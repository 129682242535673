import Link from 'next/link'
const Footer = () => {
  return (
    <footer className="text-gray-heading body-font relative bg-white">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <p className="text-sm text-gray-heading sm:py-2 sm:mt-0 mt-4"><span className="font-bold">© 2022 Protiviti Inc.</span> An Equal Opportunity Employer M/F/Disability/Veterans.
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start sm:py-2">
          <nav className="mb-1 text-gray-heading flex flex-row footer-nav">
          <Link href="/privacy-policy"><a className="font-bold text-sm ml-3 hover:text-gray-orange mb-3">Privacy Policy</a></Link>     
          <Link href="/terms-of-service"><a className="font-bold text-sm ml-20 hover:text-gray-orange mb-3">Terms of Service</a></Link>     
          </nav>
        </span>
      </div>
    </footer>
  )
}

export default Footer;